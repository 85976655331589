'use client'

import { trackEvent } from '@/lib/tracking'
import { useCallback, useEffect, useRef } from 'react'
import { DEBUG } from '@/lib/env'

type SelectionTrackerProps = React.PropsWithChildren<{
  /**
   * Minimum length of selected text to trigger tracking
   * @default 2
   */
  minLength?: number
  /**
   * Maximum length of selected text to include in tracking
   * @default 200
   */
  maxTextLength?: number
  /**
   * Delay in ms before tracking selection to avoid tracking during active selection
   * @default 500
   */
  trackDelay?: number
  /**
   * Additional props to include in the tracking event
   */
  eventProps?: Record<string, unknown>
  /**
   * The HTML element to use as the wrapper
   * @default div
   */
  as?: React.ElementType
  className?: string
}>

/**
 * Client component that tracks text selection events
 * Optimized to avoid tracking during active selection and to limit text length
 */
export function SelectionTracker({
  children,
  minLength = 2,
  maxTextLength = 200,
  trackDelay = 500,
  eventProps = {},
  as: Element = 'div',
  className = '',
}: SelectionTrackerProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleSelection = useCallback(() => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      if (DEBUG) console.log('[SelectionTracker] Clearing previous timeout')
      clearTimeout(timeoutRef.current)
    }

    // Get the current selection
    const selection = window.getSelection()
    if (!selection) {
      if (DEBUG) console.log('[SelectionTracker] No selection found')
      return
    }

    const selectedText = selection.toString().trim()
    if (!selectedText) {
      if (DEBUG) console.log('[SelectionTracker] Empty selection')
      return
    }
    
    if (selectedText.length < minLength) {
      if (DEBUG) console.log(`[SelectionTracker] Selection too short (${selectedText.length} < ${minLength}):`, selectedText)
      return
    }

    if (DEBUG) console.log(`[SelectionTracker] Valid selection (${selectedText.length} chars):`, selectedText)

    // Set a timeout to ensure the user has finished selecting
    timeoutRef.current = setTimeout(() => {
      // Truncate text if needed
      const truncatedText = selectedText.length > maxTextLength
        ? selectedText.substring(0, maxTextLength) + '...'
        : selectedText

      if (DEBUG) {
        console.log('[SelectionTracker] Tracking selection:', {
          text: truncatedText,
          originalLength: selectedText.length,
          wasTruncated: selectedText.length > maxTextLength,
          props: eventProps
        })
      }

      // Track the selection event
      trackEvent('Text Selected', {
        text: truncatedText,
        text_length: selectedText.length,
        was_truncated: selectedText.length > maxTextLength,
        ...eventProps
      })

      timeoutRef.current = null
    }, trackDelay)
  }, [minLength, maxTextLength, trackDelay, eventProps])

  useEffect(() => {
    if (DEBUG) console.log('[SelectionTracker] Setting up selection listener')
    
    // Add selection change listener
    document.addEventListener('selectionchange', handleSelection)

    return () => {
      // Clean up
      if (timeoutRef.current) {
        if (DEBUG) console.log('[SelectionTracker] Cleaning up timeout on unmount')
        clearTimeout(timeoutRef.current)
      }
      if (DEBUG) console.log('[SelectionTracker] Removing selection listener')
      document.removeEventListener('selectionchange', handleSelection)
    }
  }, [handleSelection])

  return (
    <Element className={className}>
      {children}
    </Element>
  )
} 