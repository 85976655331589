'use client'

import { sendGTMEvent } from '@next/third-parties/google'
import { CAUSIQ_KEY, ENABLE_TRACKING, GTM_ID } from './env'

// Track names should be in the format of "Category Action"
// Example: "Product Viewed", "Cart Updated", "Checkout Completed"
export type TrackEventName = 
  // Product events
  | 'Product Viewed'
  | 'Product Added'
  | 'Product Removed'
  | 'Product Updated'
  | 'Product List Viewed'
  | 'Product List Filtered'
  
  // Cart events
  | 'Cart Viewed'
  | 'Cart Updated'
  | 'Cart Item Added'
  | 'Cart Item Removed'
  | 'Cart Item Updated'
  
  // Checkout events
  | 'Checkout Started'
  | 'Checkout Step Viewed'
  | 'Checkout Step Completed'
  | 'Payment Method Selected'
  | 'Order Completed'
  | 'Order Refunded'
  
  // B2B events
  | 'Store Created'
  | 'Store Updated'
  | 'Inventory Updated'
  | 'Price Updated'
  | 'AI Price Suggested'
  | 'AI Price Accepted'
  
  // User events
  | 'Page Viewed'
  | 'Section Viewed'
  | 'Text Selected'
  
  // Section-specific events
  | 'Hero Viewed'
  | 'Hero CTA Clicked'
  | 'Hero Media Interaction'
  | 'CTA Viewed'
  | 'CTA Button Clicked'
  | 'Pricing Viewed'
  | 'Pricing Plan Selected'
  | 'Features Viewed'
  | 'Feature Interaction'
  
  // Account events
  | 'Account Created'
  | 'Account Updated'
  | 'Signed In'
  | 'Signed Out'
  | 'Error Occurred'
  
  // Custom events
  | string

/**
 * Enhanced tracking function that integrates with Next.js built-in tracking
 * and supports multiple analytics providers
 */
export function trackEvent(
  name: TrackEventName, 
  params?: Record<string, unknown>,
  options: { 
    gtm?: boolean
    causiq?: boolean
  } = { gtm: true, causiq: true }
) {
  // Skip tracking if globally disabled
  if (!ENABLE_TRACKING) {
    return
  }
  
  // Gather common data for enrichment
  const commonData = {
    timestamp: Date.now(),
    path: typeof window !== 'undefined' ? window.location.pathname : undefined,
    url: typeof window !== 'undefined' ? window.location.href : undefined,
    referrer: typeof document !== 'undefined' ? document.referrer : undefined,
    screen_width: typeof window !== 'undefined' ? window.innerWidth : undefined,
    screen_height: typeof window !== 'undefined' ? window.innerHeight : undefined,
    market: typeof window !== 'undefined' ? window.location.hostname.split('.')[0] : undefined,
    language: typeof navigator !== 'undefined' ? navigator.language : undefined,
    page_title: typeof document !== 'undefined' ? document.title : undefined,
  }

  // Enrich data with common parameters
  const enrichedParams = {
    ...commonData,
    ...params,
  }

  // Google Tag Manager - use Next.js built-in helper
  if (options.gtm && GTM_ID) {
    sendGTMEvent({ event: name, ...enrichedParams })
  }
  
  // Causiq
  if (options.causiq && CAUSIQ_KEY && typeof window !== 'undefined' && window.causiq) {
    window.causiq.track(name, enrichedParams)
  }

  // Log event in development
  if (process.env.NODE_ENV === 'development') {
    console.log(`[Tracking] ${name}`, enrichedParams)
  }
}

/**
 * Track a page view event
 */
export function trackPageView(
  pageName: string,
  pageProps?: Record<string, unknown>
) {
  // Skip tracking if globally disabled
  if (!ENABLE_TRACKING) {
    return
  }
  
  // Gather page metadata
  const metadata = {
    title: typeof document !== 'undefined' ? document.title : '',
    referrer: typeof document !== 'undefined' ? document.referrer : '',
    url: typeof window !== 'undefined' ? window.location.href : '',
    path: typeof window !== 'undefined' ? window.location.pathname : '',
    ...pageProps
  }

  trackEvent('Page Viewed', {
    page_name: pageName,
    ...metadata
  })

  // Set page properties in Causiq if available
  if (CAUSIQ_KEY && typeof window !== 'undefined' && window.causiq) {
    window.causiq.setPageProps(metadata)
  }
}

// Define types for Causiq
declare global {
  interface Window {
    causiq?: {
      init: (key: string, config?: any) => void
      track: (name: string, props?: Record<string, unknown>) => void
      setPageProps: (props: Record<string, unknown>) => void
      setUserProps: (props: Record<string, unknown>) => void
      identify: (userId: string, props?: Record<string, unknown>) => void
      navigate: (url: string) => void
    }
  }
} 